import myDialog from"@/components/myDialog/index.vue" //立即咨询弹出层
import moveMyDialog from"@/components/myDialog/moveIndex.vue" //立即咨询弹出层 移动端
import titleBox from "@/components/titleBox/index.vue"  //标题两边装饰
import moveTitleBox from "@/components/titleBox/moveIndex.vue"  //标题两边装饰 移动端
import myFooter from "@/components/commonality/index.vue" //底部公用的

function plugins(Vue) {
  Vue.component("myDialog",myDialog)
  Vue.component("moveMyDialog",moveMyDialog)
  Vue.component("titleBox",titleBox)
  Vue.component("moveTitleBox",moveTitleBox)
  Vue.component('myFooter',myFooter)
}
export default plugins