/**
 * 全局混入
 */
export default {
  /**
   * 全局方法
   */
  methods: {
    mySkip(url) {
      this.$router.push({ path: url })
    }
  }
}
