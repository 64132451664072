import Vue from 'vue'
import App from './App.vue'
//引入vueRouter
import vueRouter from "vue-router"
//引入路由配置文件
import router from "@/router/index.js"
//路由守卫配置文件
import "@/router/permission.js"
//全局初始化样式
import 'normalize.css'
import '@/styles/index.scss'

//引入一些配置
import MyPlugin from '@/plugins/index.js'

//引入ElementUI组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

//应用一些自定义的配置
Vue.use(MyPlugin)
//应用插件
Vue.use(vueRouter)
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
