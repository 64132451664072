<template>
  <div class="myBox">
      <div class="myBox-up">
        <div class="myBox-row">

          <div class="myBox-col">
            <div class="myBox-one-name">联系我们</div>
            <div class="myBox-one-chunk">
              <div class="myBox-one-left">
                <el-image class="public-pic" :src="require('@/assets/components/commonality/vcode.png')" />
              </div>
              <div class="myBox-one-right">
                <div class="dh-style">400-656-0008</div>
                <div>全国服务热线</div>
                <div class="yx-style">jinchui1002@163.com</div>
                <div>邮箱</div>
              </div>
            </div>
          </div>
          <div style="border: 1px solid #ECECEC; opacity: 0.2;height: calc(100vw * 150 / 1920);"></div>
          <div class="myBox-col">
            <div class="myBox-one-name">解决方案</div>
            <div class="myBox-one-chunk">
              <div class="myBox-one-left">
                <el-link @click="to('/solution/digital')" :underline="false" target="_blank">OA协同办公管理系统</el-link>
                <el-link @click="to('/solution/overview')" :underline="false" target="_blank">分布式制能制造系统（物联感知平台）</el-link>
                <el-link @click="to('/solution/video')" :underline="false" target="_blank">视频监控管理系统</el-link>
                <el-link @click="to('/solution/lifter')" :underline="false" target="_blank">升降机安全监控管理系统</el-link>
              </div>
              <div class="myBox-one-right">
                <el-link @click="to('/solution/lifecycle')" :underline="false" target="_blank">档案管理系统</el-link>
                <el-link @click="to('/solution/colony')" :underline="false" target="_blank">门户网站建设及应用平台 [机构/企业]</el-link> 
                <el-link @click="to('/solution/surroundings')" :underline="false" target="_blank">环境监测管理系统</el-link>
                <el-link @click="to('/solution/qualityinspection')" :underline="false" target="_blank">质量检测管理平台</el-link>
              </div>

              <div class="myBox-one-right">
                <el-link @click="to('/solution/visualization')" :underline="false" target="_blank">大数据共享服务平台</el-link>
                <el-link @click="to('/solution/realName')" :underline="false" target="_blank">建筑工人实名制管理系统</el-link>
                <el-link @click="to('/solution/towercrane')" :underline="false" target="_blank">塔机安全监控管理系统</el-link>
                <el-link @click="to('/solution/deeppit')" :underline="false" target="_blank">深基坑监测管理系统</el-link>
              </div>
              <div class="myBox-one-right">
                <el-link @click="to('/solution/standardSpecification')" :underline="false" target="_blank">数字化管理基础平台</el-link>
                <el-link @click="to('/solution/workerwage')" :underline="false" target="_blank">建筑工人工资管理</el-link>
                <el-link @click="to('/solution/loadhook')" :underline="false" target="_blank">塔机吊钩导航系统</el-link>
                <el-link @click="to('/solution/digitalTwin')" :underline="false" target="_blank">数字孪生平台</el-link>
              </div>

            </div>
          </div>
          <div style="border: 1px solid #ECECEC; opacity: 0.2;height: calc(100vw * 150 / 1920);"></div>
          <div class="myBox-col">
            <div class="myBox-one-name">友情链接</div>
            <div class="myBox-one-chunk">
              <div class="myBox-one-left">
                <el-link href="https://www.aliyun.com" :underline="false" target="_blank">阿里云</el-link>
                <el-link href="http://proudsmart.com" :underline="false" target="_blank">普奥云</el-link>
                <el-link href="https://www.tongfangpc.com" :underline="false" target="_blank">清华同方</el-link>
                <el-link href="http://www.10010.com/net5/074" :underline="false" target="_blank">中国联通</el-link>
              </div>
              <div class="myBox-one-right">
                <el-link href="https://www.hncu.edu.cn" :underline="false" target="_blank">湖南城市学院</el-link>
                <el-link href="https://www.dahuatech.com" :underline="false" target="_blank">大华科技</el-link>
                <el-link href="http://www.zeno-tech.com" :underline="false" target="_blank">杭州智诺</el-link>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div class="myBox-reference">
        <el-link href="http://beian.miit.gov.cn" :underline="false" target="_blank">湘ICP备19012616号</el-link>
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1801227859973324800&language=CN&certType=8&url=www.jcsmart.com.cn" target="_blank" style="position: relative;display: inline-block;height: 38px;margin-left: 10px;"><div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1801227859973324800=www.jcsmart.com.cn.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
      </div>
  </div>
</template>

<script>
export default {
  name: "myFooter",
  methods: {
    to(url) {
      this.$router.push({ path: url });
    },
  },
}
</script>

<style scoped>
.myBox {
  height: calc(100vw * 250 / 1920);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}


.myBox-up {
  height: 80%;
  background-color: #222429;
}

.myBox-row {
  margin: auto;
  width: calc(100vw * 1200 / 1920);;
  height: 100%;
  padding-top: calc(100vw * 12 / 1920);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myBox-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.myBox-one-name {
  font-size: calc(100vw * 18 / 1920);
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: calc(100vw * 36 / 1920);
}

.myBox-one-chunk {
  margin-top: 10px;
  flex: 1;
  display: flex;
}

.myBox-one-left,
.myBox-one-right {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  line-height: calc(100vw * 25/ 1920);
}

.myBox-one-right {
  margin-left: calc(100vw * 20 / 1920);
}

.el-link {
  color: #FFFFFF !important;
  text-align: left;
  justify-content: start;
  font-size: calc(100vw * 13 / 1920);
}
.el-link:hover{
  color: #409EFF !important;
}
.contact {
  line-height: calc(100vw * 40/ 1920);
  font-size: calc(100vw * 14 / 1920);
}

.public {
  display: flex;
  height: 100%;
}

.public-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(100vw * 14 / 1920);
}

.public-pic {
  margin: calc(100vw * 6/ 1920) 0;
  width: calc(100vw * 100/ 1920);
  height: calc(100vw * 100/ 1920);
  font-size: 0;
}

.myBox-reference {
  height: 20%;
  background-color: #151922;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(100vw * 14 / 1920);
}
.public-pic-div{
  margin-left: 26px;
}
.dh-style{
  font-size: calc(100vw * 18/ 1920);
  color: #FFFFFF;
  line-height: calc(100vw * 32/ 1920);
}
.yx-style{
  font-size: calc(100vw * 14/ 1920);
  color: #FFFFFF;
  line-height: calc(100vw * 32/ 1920);
}
</style>