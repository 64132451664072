<template>
  <div class="xy-title">
    <img class="xy-title-pic" src="@/assets/components/titleBox/left.png" alt="">
    <slot name="title"/>
    <img class="xy-title-pic" src="@/assets/components/titleBox/right.png" alt="">
  </div>
</template>

<script>
export default {
  name:"moveTitleBox"
}
</script>

<style scoped>
.xy-title {
    display: flex;
    justify-content: center;
    align-items: center;
}
.xy-title-pic {
  margin: auto 2%;
}

</style>