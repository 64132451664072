<template>
  <el-container>
      <el-header>
          <moveViewsHeadBar></moveViewsHeadBar>
      </el-header>
      <el-main>
          <AppMain></AppMain>
      </el-main>
  </el-container>
</template>

<script>
import moveViewsHeadBar from "@/layout/componentss/moveViewsHeadBar.vue";
import AppMain from "@/layout/componentss/main.vue";
export default {
  name: "myLayout",
  components: {
    moveViewsHeadBar,
    AppMain
  }
}
</script>

<style scoped>
/* 隐藏滚动条 */
:-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.el-container{
  width: 100%;
  height: 100%;
}
.el-header {
  padding: 0 !important;
}

.el-main {
  padding: 0 !important;
}
</style>