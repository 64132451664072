<template>
    <div class="myHead">
        <div class="myHead-box">
            <div class="logo">
                <img class="logo-pic" src="@/assets/logo/logo.png" alt="logo">
            </div>
            <el-menu :default-active="$route.path" background-color="#14171b" text-color="#FFFFFF" router
                active-text-color="#3C9BED" :unique-opened="true" :collapse-transition="false" mode="horizontal"
                class="menu">
                <el-menu-item index="/">首页</el-menu-item>
                <el-menu-item index="/solution" :class="{ 'is-active': routerPath.indexOf('/solution') == 0 }">产品与服务
                </el-menu-item>
                <el-menu-item index="/journalism" :class="{ 'is-active': routerPath.indexOf('/journalism') == 0 }">新闻资讯
                </el-menu-item>
                <el-menu-item index="/successfulCase" :class="{ 'is-active': routerPath.indexOf('/successfulCase') == 0 }">
                    成功案例</el-menu-item>
                <!-- <el-menu-item index="/download">软件下载</el-menu-item> -->
                <el-menu-item index="/aboutUs">关于我们</el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: "headBar",
    computed: {
        routerName() {
            return this.$route.meta.title;
        },
        routerPath() {
            return this.$route.path;
        }
    },
}
</script>

<style scoped>
.myHead {
    height: 100%;
    background-color: #14171b;
}

.myHead-box {
    height: 100%;
    width: calc(100vw * 1200 / 1920);
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    display: flex;
}

.logo-pic {
    height: 40px;
    width: auto;
}

.right {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-menu {
    border-right: none;
    height: 100%;
}

.el-menu.el-menu--horizontal {
    border-bottom: none;
}

.is-active {
    color: #fff !important;
    border-bottom-color: #3179FD !important;
    background-color: #3179FD !important;
}
</style>