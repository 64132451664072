<template>
    <router-view :key="goPath" />
</template>

<script>
export default {
    name: "appMain",
    created() {
    },
    computed: {
        goPath() {
            return this.$route.path
        }
    }
}
</script>

<style>
</style>